<template>
  <div id="app">
    <div>
      <!-- <loading :active="$store.state.isLoading" :can-cancel="false" :is-full-page="true"></loading> -->
      <router-view></router-view>
      <!-- <stripe-card /> -->
    </div>
  </div>
</template>

<script>
// import StripeCard from './components/StripeCard.vue';
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
import API from "./config/api";

export default {
  components: {
    // StripeCard
    // Loading,
  },
  created() {
    this.verifyUserToken().then( (result) => {
      if (false === result) {
        this.$store.state.user = null;
      }
    });


    this.verify();

  },
  mounted() {
    this.$store.state.sub_history = JSON.parse(localStorage.getItem('sub_history') || '[]');
  },
  methods: {
    /**
     * Verifies the user token.
     *
     * This function retrieves the user and token from the local storage.
     * If the user or token is not present, it cleans up the local storage.
     * Otherwise, it makes a POST request to the "/verify-user-token" endpoint
     * with the user's ID and token. If the response say the token is invalid,
     * it cleans up the local storage.
     */
    async verifyUserToken() {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("token");

      if (!user || !token) {
        this.cleanUpLocalStorage();
        return false;
      }

      try {
        const {data, status} = await API.post("/verify-user-token", { id: user.id, token: token });
        if( status === 200 && data.status === true){
          return true;
        }

        this.cleanUpLocalStorage();
        return false;
      } catch (error) {
        console.log('Somethings went wrong', error);
        return false;
      }
    },
    async verify() {
      try {
        const { data, status } = await API.get("/verify");
        if (status === 200) {
          this.$store.state.user = data;
        } else {
          this.$store.state.user  = null;
        }

        const { lws_data, lws_status } = await API.get("/list-web-subscription");
        if( lws_status === 200 ) {
          this.$store.state.sub_history = lws_data.subscriptions;
        } else {
          this.$store.state.sub_history = null;
        }
      } catch (error) {
        if( error.status !== 401) {
          console.log("Getting errors while verify user", error);
        }
      }
    },
    // initializeActiveCampaign() {
    //   if (typeof window.vgo !== 'undefined') {
    //     window.vgo('setAccount', '8');
    //     window.vgo('setTrackByDefault', true);
    //     window.vgo('process');
    //   }
    // },
    /**
     * Removes all Believe-related data from local storage.
     *
     * @return {void}
     */
    cleanUpLocalStorage() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("promo_code");
      localStorage.removeItem("has_promo");
      localStorage.removeItem("package_type");
    },
  },
};
</script>

<!-- <style>

.main-terms-content {
    padding-top: 0px !important;
}

</style> -->
