<template>
  <div class="headsection">
    <header id="header">
      <!-- header -->
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="logo">
              <router-link to="/" title="Believe">               
                <img class="" src="../../public/assets/images/logo-footer.png">
              </router-link>
            </div>
            <nav id="primary-menu">
              <ul style="position: relative">
                <li v-if="$store.state.user != null" class="lock-btn">
                  <a type="button" title="" @click="logout()"
                    ><i class="fa fa-lock"></i> Logout</a
                  >
                </li>
                <li v-if="$store.state.user == null" class="lock-btn">
                  <router-link type="button" class="login-btn" to="/login" title=""
                    ><i class="fa fa-key"></i> Login</router-link
                  >
                </li>
                <li v-if="$store.state.user != null" class="lock-btn">
                  <router-link type="button" to="/manage" title=""
                    ><i class="fa fa-cog"></i> Manage Subscriptions</router-link
                  >
                </li>
              </ul>
              <router-link
                v-if="$store.state.user == null"
                to="/register"
                class="header-btn"
                title="Download"
                >Try BELIEVE Free</router-link
              >
              <router-link
                v-if="$store.state.user != null"
                to="/plans"
                class="header-btn"
                title="Download"
                >Try BELIEVE Free</router-link
              >
            </nav>
          </div>
        </div>
      </div>
    </header>

    <div id="responsive-header">
      <div class="container">
        <div class="logo">
          <router-link class="" to="/">
            <!-- <span class="logo-title">Believe</span> -->
            <img class="" src="../../public/assets/images/logo-footer.png">
          </router-link>
        </div>
        <!-- && $store.state.revcatSubType != 'Yearly' -->

        <span class="responsviemenu-btn resp-btn"><i class="fa fa-bars"></i></span>
        <span v-if="$store.state.user == null" class="responsviemenu-btn mbl-btn"><router-link to="/register" class="">Try BELIEVE Free</router-link></span>
        <span v-if="$store.state.user != null" class="responsviemenu-btn mbl-btn"><router-link to="/plans" class="">Try BELIEVE Free</router-link></span>
        <div class="responsive-menu">
          <span class="close-btn"><i class="fas fa-times"></i></span>
          <ul>
            <li v-if="$store.state.user != null" class="lock-btn">
              <a type="button" title="" @click="logout()"
                ><i class="fa fa-lock"></i> Logout</a
              >
            </li>
            <li v-if="$store.state.user == null" class="lock-btn">
              <router-link type="button" class="login-btn" to="/login" title=""
                ><i class="fa fa-key"></i> Login</router-link
              >
            </li>
            <li v-if="$store.state.user != null" class="lock-btn">
              <router-link type="button" to="/manage" title=""
                ><i class="fa fa-cog"></i> Subscriptions</router-link
              >
            </li>
          </ul>
          <router-link
            v-if="$store.state.user == null"
            to="/register"
            class="header-btn"
            title="Download"
          >
          Try BELIEVE Free
          </router-link>
          <router-link
            v-if="$store.state.user != null"
            to="/plans"
            class="header-btn"
            title="Download"
          >
          Try BELIEVE Free
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headernew",

  data() {
    return {
      loginBtn: 'login-btn',
      display: true,
      selected_sub_id: null,
      loggedUserToken: null,
      boolloggedUserToken: false,
    };
  },
  props: {
    msg: String,
  },
  mounted() {
    console.log(this.$store.state.user)
    this.checkLogin();
  },
  methods: {
    logout() {
      localStorage.removeItem("sub_history");
      localStorage.removeItem("revcatSubType");
      localStorage.removeItem("revcatSubType1");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("promo_code");
      localStorage.removeItem("has_promo");
      localStorage.removeItem("package_type");
      this.$store.state.user = null;
      this.$router.push("/login");
    },
    checkLogin() {
      this.loggedUserToken = localStorage.getItem("token");
      if (this.loggedUserToken != null) {
        this.boolloggedUserToken = true;
      } else {
        this.boolloggedUserToken = false;
      }
      console.log("Get token?", this.boolloggedUserToken);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#header {
  background: #071649;
}
#header span.logo-title {
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 24px;
  color: white;
  letter-spacing: 0.02em;
  padding: 17px 17px 17px 0;
}
nav ul li.log-btn > a:hover {
  background-color: transparent !important;
  opacity: 0.9;
}
a.header-btn {
    border-color: #3DBCB8 !important;
}
</style>
