<template>
  <div class="subscription-detail-section main-terms-content">
    <loadingOverlay :is-loading="loading" />
    <MainHeader></MainHeader>
    <section class="pricings subscription-info-main" id="pricings">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 heading-subcription">
            <h2>Subscription Details</h2>
          </div>
        </div>
        <div v-if="error_message" class="alert alert-danger" role="alert">
          {{ this.error_message }}
        </div>
        <div v-if="success_message" class="alert alert-success" role="alert">
          {{ this.success_message }}
        </div>

        <!-- <div class="row" v-if="$store.state.sub_history">
          <div
            class="col-md-6 p-4"
            v-for="data in $store.state.sub_history"
            :key="data.id"
          >
            <div class="content-column">
              <h3>Subscription</h3>

              <div class="subscription-inner">
                <div class="inner-middle">
                  <p class="sub-head">Subcription Type:</p>
                  <p class="sub-detail">
                    {{ $store.state.revcatSubType.toUpperCase() }}
                    <br />
                  </p>
                </div>
                <div class="inner-middle">
                  <p class="sub-head">Valid through:</p>
                  <p class="sub-detail">
                    <i class="fa fa-calendar-alt"></i>
                    {{ data.expires_date | moment("LL") }}
                  </p>
                </div>
                <div class="inner-middle">
                  <p class="sub-head">Platform:</p>
                  <p class="sub-detail">
                    <i class="fa fa-cash"></i>
                    {{ data.store.toUpperCase() }}
                  </p>
                </div>

                <div class="inner-middle">
                  <p class="sub-head">Period Type:</p>
                  <p class="sub-detail">
                    <span
                      :class="
                        data.period_type == 'trial' ||
                        data.period_type == 'normal'
                          ? 'text-success'
                          : 'text-danger'
                      "
                    >
                      {{ data.period_type.toUpperCase() }}
                    </span>
                  </p>
                </div>

                <div v-if="data.unsubscribe_detected_at" class="inner-middle">
                  <p class="sub-head">Unsubscribed at:</p>
                  <p class="sub-detail">
                    <span
                      class="text-danger"

                    >
                      {{ data.unsubscribe_detected_at | moment("LL")}}
                    </span>
                  </p>
                </div>



                <div class="inner-right">
                  <a
                    class="active-cancel-btn"
                    v-if="
                      (data.store == 'stripe' || data.store == 'paypal') &&
                      !data.unsubscribe_detected_at
                    "
                    @click="openModal(stripe_subscription_id, data.period_type)"
                    >Cancel</a
                  >
                  <router-link
                    v-if="
                      (data.period_type != 'canceled' &&
                        data.store == 'stripe' && hidePlans == false)

                    "
                    to="/plans"
                    >View All Plans
                  </router-link>
                  <router-link
                    v-if="data.store != 'stripe' && data.store != 'paypal'"
                    to="/help"
                    >Manage Subscriptions
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div v-if="Object.keys(this.$store.state.sub_history).length == 0">
          <div class="col-md-8 p-4 content-column">
            <p>No Subscriptions Were Found.</p>
          </div>
        </div> -->


        <div v-if="subscription_not_found == 'waiting'">
          <!-- <h1>Loading....</h1> -->
          <div class="col-md-8 p-4 content-column">
            <p>Loading....</p>
          </div>
        </div>

        <div class="row" v-else-if="subscription_not_found == 'found'">
          <!-- <h1>Subscribed</h1> -->
          <div
            class="col-md-6 p-4"
            v-for="data in $store.state.sub_history"
            :key="data.id"
          >

          <div v-if="data.unsubscribe_detected_at && $store.state.revcatSubType.toUpperCase() == 'YEARLY'" class="alert alert-success" role="alert">
            Your subscription has been canceled. Now, you can subscribe again after the next renewal date.
          </div>

            <div class="content-column">
              <h3>Subscription</h3>

              <div class="subscription-inner">
                <div class="inner-middle">
                  <p class="sub-head">Subcription Type:</p>
                  <p class="sub-detail">
                    {{ $store.state.revcatSubType.toUpperCase() }}
                    <br />
                  </p>
                </div>
                <div class="inner-middle">
                  <p class="sub-head">Valid through:</p>
                  <p class="sub-detail">
                    <i class="fa fa-calendar-alt"></i>
                    {{ data.expires_date | moment("LL") }}
                  </p>
                </div>
                <div class="inner-middle">
                  <p class="sub-head">Platform:</p>
                  <p class="sub-detail">
                    <i class="fa fa-cash"></i>
                    {{ data.store.toUpperCase() }}
                  </p>
                </div>

                <div class="inner-middle">
                  <p class="sub-head">Period Type:</p>
                  <p class="sub-detail">
                    <span
                      :class="
                        data.period_type == 'trial' ||
                        data.period_type == 'normal'
                          ? 'text-success'
                          : 'text-danger'
                      "
                    >
                      {{ data.period_type.toUpperCase() }}
                    </span>
                  </p>
                </div>

                <div v-if="data.unsubscribe_detected_at" class="inner-middle">
                  <p class="sub-head">Unsubscribed at:</p>
                  <p class="sub-detail">
                    <span
                      class="text-danger"

                    >
                      {{ data.unsubscribe_detected_at | moment("LL")}}
                    </span>
                  </p>
                </div>



                <div class="inner-right">
                  <a
                    class="active-cancel-btn"
                    v-if="
                      (data.store == 'stripe' || data.store == 'paypal') &&
                      !data.unsubscribe_detected_at
                    "
                    @click="openModal(stripe_subscription_id, data.period_type)"
                    >Cancel</a
                  >
                  <router-link
                    v-if="
                      (data.period_type != 'canceled' &&
                        data.store == 'stripe' && hidePlans == false)

                    "
                    to="/plans"
                    >View All Plans
                  </router-link>
                  <router-link
                    v-if="data.store != 'stripe' && data.store != 'paypal'"
                    to="/help"
                    >Manage Subscriptions
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="subscription_not_found == 'not found'">
          <!-- <h1>No Subscription Found!</h1> -->
          <div class="col-md-8 p-4 content-column">
            <p>No Subscriptions Were Found.</p>
          </div>
        </div>



      </div>
    </section>

    <Footer></Footer>
    <modal name="confirm_modal" :height="80" :width="250" :adaptive="true">
      <div class="model-iiner">
        <h4>Are you sure?</h4>
        <ul>
          <li class="curp" @click="modalAction('yes')">Yes</li>
          <li class="curp" @click="modalAction('no')">No</li>
        </ul>
      </div>
    </modal>
  </div>
</template>

<script>
import { userApiMixin } from "../mixins/userApiMixin";
import MainHeader from "../components/MainHeader.vue";
import Footer from "../components/footer.vue";
import API from "../config/api";
import loadingOverlay from '../components/loadingOverlay.vue';
// import Loading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  mixins: [userApiMixin],
  components: {
    MainHeader,
    Footer,
    loadingOverlay
    // Loading
  },
  data() {
    return {
      loading: false,
      subscription_not_found : 'waiting',
      selected_sub_id: null,
      error_message: null,
      success_message: null,
      stripe_subscription_id: null,
      stripeYearlyProductId: "prod_PeXl3NPNKwpK8W",
      stripeMonthlyProductId: "prod_PeXlN6gfC9R6ES",
      // IosYearlyProductId: "31012023",
      // IosMonthlyProductId: "3101202313",
      // AndroidMonthlyProductId: "25102022653",
      // AndroidYearlyProductId: "29102022152",

        IosYearlyProductId: "114092023",
        IosMonthlyProductId: "014092023",
        AndroidMonthlyProductId: "monthly_014092023",
        AndroidYearlyProductId: "yearly_114092023",

      subPid: null,
      isLoading: true,
      fullPage: true,
      activeSub: [],
      maxDate: null,
      revsub: {},
      entitlement: {},
      allData: [],
      hidePlans: false
    };
  },
  mounted() {
    console.log("Current state", this.$store.state);
  },
  computed: {
    // goToPlan(){
    //   return this.$store.state.user.user_subscription.subscription_type == "Yearly"
    //   ? "Downgrade Subscription" : this.$store.state.user.user_subscription.subscription_type == "Monthly"
    //   ? "Upgrade Subscription" :
    //    "View All Plans";
    // }
  },
  async created() {
    this.verifyUser();
    this.getUserSubs();
  },
  methods: {
    async getUserSubs(attempts = 0) {
      try {
        this.loading = true;
        let array_1 = [];
        let array_2 = [];
        let array_3 = [];
        this.$store.state.sub_history = [];
        this.$store.state.revcatSubType = "Free";


        if (attempts >= 5) {
          // If maximum attempts reached, exit the function.
          this.loading = false;
          this.subscription_not_found = 'not found';
          return;
        }


        // let whileNum = 0;
        // while (Object.keys(this.entitlement).length === 0) {
        //   if(whileNum == 5) break;
        //   whileNum = whileNum + 1;


        const { data:sub_history , status} = await API.get("/list-web-subscription");
        if (status === 200) {
          this.revsub = sub_history?.subscriptions?.subscriber?.subscriptions;
          this.entitlement = sub_history?.subscriptions?.subscriber?.entitlements;
          console.log("rev: ", this.revsub)
          if (this.revsub) {
            for (const [key, value] of Object.entries(this.revsub)) {
              console.log(key)
              array_1.push(value);
            }
          }
          console.log("ent: ", this.entitlement)
          if (this.entitlement) {
            for (const [key, value] of Object.entries(this.entitlement)) {
              console.log(key)
              array_2.push(value);
            }
          }
        }

          const maxDate = new Date(Math.max(...array_2.map(e => new Date(e.purchase_date))));
          console.log("maxTime", maxDate);

          const check_array_filter = array_1.filter(function (data){
            if(new Date(Math.max(new Date(data.purchase_date))).getTime() === maxDate.getTime()){
              return true;
            }
          });

          // console.log("chekc rry filter ::::: " , check_array_filter);
          array_3 = array_2.filter(function (data){
            if(new Date(Math.max(new Date(data.purchase_date))).getTime() == maxDate.getTime()){
              return true;
            }
          });

          this.subPid = array_3[0].product_identifier;

          console.log("array3: ", array_3);
          console.log("active subscription: ", this.$store.state.sub_history)
          console.log("all subscriptions: ", sub_history)

          if(this.subPid == this.stripeMonthlyProductId || this.subPid == this.IosMonthlyProductId || this.subPid == this.AndroidMonthlyProductId){
            this.$store.state.revcatSubType = "Monthly";
            localStorage.setItem('revcatSubType','Monthly');
          }
          if(this.subPid == this.stripeYearlyProductId || this.subPid == this.IosYearlyProductId || this.subPid == this.AndroidYearlyProductId){
            this.$store.state.revcatSubType = "Yearly";
            localStorage.setItem('revcatSubType','Yearly');
            this.hidePlans = true;
          }

          if(check_array_filter.length > 0){
            const givenDateString = check_array_filter[0].expires_date;
            const givenDate = new Date(givenDateString);
            const currentDate = new Date();
            if (givenDate >= currentDate) {
              this.$store.state.sub_history = check_array_filter;
              this.loading = false;
              this.subscription_not_found = 'found';
            } else if (givenDate < currentDate) {
              this.loading = false;
              this.subscription_not_found = 'not found';
              this.$store.state.revcatSubType = 'Free';
              localStorage.setItem('revcatSubType','Free');
            }
          }else{
            this.loading = false;
            this.subscription_not_found = 'not found';
            this.$store.state.revcatSubType = 'Free';
              localStorage.setItem('revcatSubType','Free');
          }

          this.stripe_subscription_id = sub_history.subscriptions?.subscriber?.subscriber_attributes?.subscription_id?.value;

            if (Object.keys(this.entitlement).length === 0) {
              await new Promise(resolve => setTimeout(resolve, 2000));
            }
        // }
        this.isLoading = false;
      } catch (error) {
        if(localStorage.getItem('revcatSubType') == "Yearly" || localStorage.getItem('revcatSubType') == "Monthly"){
          setTimeout(() => {
            this.getUserSubs(attempts + 1);
          }, 2000);
        }else{
          this.loading = false;
          this.subscription_not_found = 'not found';
          console.log(error);
        }
     }
     finally {
        this.isLoading = false;
    }

    },


    async getUserSubsForCancel() {
      try {
        this.loading = true;
        let array_1 = [];
        let array_2 = [];
        let array_3 = [];
        this.$store.state.sub_history = [];
        this.$store.state.revcatSubType = "Free";
        console.log('ent getusersubs :::: ' , this.entitlement);

          const sub_history = await API.get("/list-web-subscription");

        this.revsub = sub_history?.data?.subscriptions?.subscriber?.subscriptions;
        this.entitlement = sub_history?.data?.subscriptions?.subscriber?.entitlements;

        console.log("rev: ", Object.values(this.revsub))
        console.log("ent: ", Object.values(this.entitlement))

        for (const [key, value] of Object.entries(this.revsub)) {
          console.log(key)
          array_1.push(value);
        }

        for (const [key, value] of Object.entries(this.entitlement)) {
          console.log(key)
          array_2.push(value);
        }

        const maxDate = new Date(Math.max(...array_2.map(e => new Date(e.purchase_date))));
        console.log("maxTime", maxDate);

        const check_array_filter = array_1.filter(function (data){
          if(new Date(Math.max(new Date(data.purchase_date))).getTime() == maxDate.getTime()){
            return true;
          }
        });

        // console.log("chekc rry filter ::::: " , check_array_filter);
        array_3 = array_2.filter(function (data){
          if(new Date(Math.max(new Date(data.purchase_date))).getTime() == maxDate.getTime()){
            return true;
          }
        });

        this.subPid = array_3[0].product_identifier;

        console.log("array3: ", array_3);
        console.log("active subscription: ", this.$store.state.sub_history)
        console.log("all subscriptions: ", sub_history.data)

        if(this.subPid == this.stripeMonthlyProductId || this.subPid == this.IosMonthlyProductId || this.subPid == this.AndroidMonthlyProductId){
          this.$store.state.revcatSubType = "Monthly";
          localStorage.setItem('revcatSubType','Monthly');
        }
        if(this.subPid == this.stripeYearlyProductId || this.subPid == this.IosYearlyProductId || this.subPid == this.AndroidYearlyProductId){
          this.$store.state.revcatSubType = "Yearly";
          localStorage.setItem('revcatSubType','Yearly');
          this.hidePlans = true;
        }

        if(check_array_filter.length > 0){
          const givenDateString = check_array_filter[0].expires_date;
          const givenDate = new Date(givenDateString);
          const currentDate = new Date();
          if (givenDate >= currentDate) {
            this.$store.state.sub_history = check_array_filter;
            this.loading = false;
            this.subscription_not_found = 'found';
          } else if (givenDate < currentDate) {
            this.loading = false;
            this.subscription_not_found = 'not found';
            this.$store.state.revcatSubType = 'Free';
            localStorage.setItem('revcatSubType','Free');
          }
        }else{
          this.loading = false;
          this.subscription_not_found = 'not found';
          this.$store.state.revcatSubType = 'Free';
            localStorage.setItem('revcatSubType','Free');
        }

        this.stripe_subscription_id = sub_history.data.subscriptions?.subscriber?.subscriber_attributes?.subscription_id?.value;
        this.isLoading = false;
      } catch (error) {
        this.loading = false;
        this.subscription_not_found = 'not found';
        console.log(error);
      }
    },
    async modalAction(answer) {
      try {
        this.error_message = null;
        if (answer == "yes") {
          this.$modal.hide("confirm_modal");
          this.success_message = "cancelling subscription...";
          const res = await API.post("/cancel-stripe-subscription", {
            sub_history_id: this.selected_sub_id,
          });
          console.log('cancel sub res :::: ' , res);
          if(res?.status == 200)
          {
            console.log('cancel sub res 1 :::: ' , this.entitlement);
            this.entitlement = {};
            console.log('cancel sub res 2 :::: ' , this.entitlement);
          }
          this.success_message = res?.data?.message;
          this.getUserSubsForCancel();
        } else this.$modal.hide("confirm_modal");
      } catch (error) {
        this.success_message = null;
        this.error_message = "Something went wrong, please try again!"
        console.log(error?.response?.data?.message);
      }
    },
    openModal(sub_id, status) {
      if (status == "normal" || status == "trial") {
        this.selected_sub_id = sub_id;
        this.$modal.show("confirm_modal");
      } else {
        console.log("no");
      }
      // console.log(sub_id, status);
    },
    async verifyUser() {
      try {
        if (this.$route?.query?.token) {
          let token = this.$route?.query?.token;
          localStorage.setItem("token", token);
        }
        const { data, status } = await API.get("/verify");
        if( status === 200 ){
          this.$store.state.user = data;
          localStorage.setItem("user", JSON.stringify(this.$store.state.user));
          return true;
        }
        return false;
      } catch (error) {
        console.log('Error while verify user: ', error);
      }
    },
  },
};
</script>

<style>
.subscription-detail-section .subscription-info-main {
  padding-top: 150px;
  font-family: Rubik;
}
.subscription-detail-section {
  background-color: #3dbcb826;
}
.subscription-detail-section .content-column {
  position: relative;
  padding: 25px 30px;
  background: rgba(61, 188, 184, 0.1);
  -webkit-backdrop-filter: blur(4.5px);
  backdrop-filter: blur(4.5px);
  border-radius: 10px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
  text-align: start;
}
.heading-subcription {
  text-align: center;
}
</style>
