import API from "../config/api";

export const userApiMixin = {
    methods: {
        async api_getUpdatedUser(user_id) {
            try {
                localStorage.removeItem('user');
                this.$store.state.user = null;
                const {data, status} = await API.post("/get-updated-user", {
                    id: user_id,
                });
                if (status === 200) {
                    this.$store.state.user = data.message;
                    localStorage.setItem("user", JSON.stringify(data.message));
                    return data.message;
                }
                return false;
            } catch (error) {
                console.log('Error while /get-updated-user request: ', error);
            }
        },

        async api_getListWebSubscription() {
            try {
                const {data, status, statusText} = await API.get("/get-list-web-subscription");
                if (status === 200) {
                    const {subscriptions, entitlements} = data.subscriptions.subscriber ?? {};
                    return {
                        data: data,
                        revsub: subscriptions ?? null,
                        entitlement: entitlements ?? null
                    }
                } else {
                    throw {status: status, data: data, message: statusText};
                }
            } catch (error) {
                console.log('Error while /get-list-web-subscription request: ', error);
                throw error;
            }
        }
    }
}