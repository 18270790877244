<template>
  <div class="page-wrapers">
    <MainHeader></MainHeader>
    <section class="same-layout fixed-height">
      <div class="inner-left pt-5">
        <div class="col-sm-12">
          <!-- <img
            src="../../public/assets/images/logo-mob.png"
            alt=""
            class="d-block"
          /> -->
          <br>
          <br>
          <br>
          <br>
          <span class="banner-para"
            ><b>DREAM</b> it, <b>BELIEVE</b> it, and <b>ACHIEVE</b> your
            DESIRES</span
          >
          <ul class="banner-list">
            <li>Speed up your manifestations</li>
            <li>Access 1000+ powerful LOA hypnosis audios</li>
            <li>Members only personal growth courses</li>
            <li>Videos, Scripts, Live Events + Much More</li>
            <li>Start Living a Happier More Abundant life now</li>
          </ul>
        </div>
      </div>
      <div class="inner-right pt-5">
        <div class="col-sm-12">
          <div id="login" class="tab-pane active text-center">
            <form class="p-a30 dez-form">
              <h3 class="form-title m-t0 mb-2">
                Create a Free BELIEVE Account
              </h3>
              <p class="form-text">
                A free account let's you save your progress and see your stats
              </p>
              <h4 class="form-title">
                Already Have An Account?
                <router-link to="/login" class="register-btn-new-flow">
                  <span>Log In</span>
                </router-link>
              </h4>
              <div v-if="error_message" class="alert alert-danger" role="alert">
                {{ this.error_message }}
              </div>
              <div
                v-if="success_message"
                class="alert alert-success"
                role="alert"
              >
                Creating Your Account ...
              </div>
              <!-- <p>Enter your email address and your password.</p> -->

              <div class="form-group">
                <input
                  name="dzName"
                  required
                  class="form-control"
                  placeholder="Full Name*"
                  type="text"
                  v-model="name"
                  v-on:keypress="isLetter($event)"
                />
              </div>
              <div class="form-group">
                <input
                  name="dzName"
                  required
                  class="form-control"
                  placeholder="Email Address*"
                  type="text"
                  v-model="email"
                />
              </div>

              <div class="form-group">
                <!-- <input
                  name="dzName"
                  required
                  class="form-control"
                  placeholder="Password (8+ characters)*"
                  type="password"
                  v-model="password"
                /> -->
                <password
                name="dzName"
                placeholder="Password (minimum 8 characters)*"
                v-model="password"
                :toggle="true"
                @score="showScore"
                @feedback="showFeedback"
                :secureLength=8
              />                
              </div>
              <div class="">
                <input
                  name="dzName"
                  required
                  class="form-control"
                  placeholder="Confirm Password*"
                  type="password"
                  v-model="retype_password"              
                />
              </div>

              <div class="form-group text-left">
                <button
                  type="button"
                  class="site-button main-btn pull-right"
                  @click="btn_register"
                  :disabled="this.btnDissable"
                  :style="[this.btnDissable == true ? { cursor: 'not-allowed', backgroundColor: 'grey' } : '']"                  
                >
                  <span>CREATE YOUR ACCOUNT</span>
                </button>
              </div>
              <!-- google and facebook login start -->
              <!-- <div class="dez-separator-new m-b5">
                <p>OR</p>
              </div>
              <div class="dz-social clearfix">
                <ul
                  class="dez-social-icon dez-border pull-right dez-social-icon-lg text-white"
                >
                  <li>
                    <button
                      type="button"
                      class="btn btn-sm fb-btn"
                      href="javascript:;"
                      @click="facebookLogin()"
                    >
                      <i class="fab fa-facebook-f"></i>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      class="btn btn-sm fb-btn"
                      href="javascript:;"
                      @click="googleLogin()"
                    >
                      <i class="fab fa-google-plus-g"></i>
                    </button>
                  </li>
                </ul>
              </div> -->
              <!-- google and facebook login end -->
              <div class="tagline-terms">
                <p>
                  By continuing, you agree with Believe's
                  <router-link to="/terms">Terms</router-link> &
                  <router-link to="/privacy">Privacy Policy</router-link>.
                  which explains how to opt out of marketing promos.
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- Content -->

    <!-- Content END-->
  </div>
</template>

<script>
import Password from 'vue-password-strength-meter'
import {
  getAuth,
  createUserWithEmailAndPassword,
  // signInWithEmailAndPassword,
  // FacebookAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import MainHeader from "../components/MainHeader.vue";
import API from "../config/api";
export default {
  data() {
    return {
      name: null,
      email: null,
      password: null,
      retype_password: null,
      error_message: "",
      success_message: false,
      btnDissable: false
    };
  },
  components: {
    MainHeader,
    Password
  },
  updated() {
  },
  methods: {

    getUserTimeZone(){
      const dtf = new Intl.DateTimeFormat();
      return dtf.resolvedOptions().timeZone;
    },

    validateEmail(email) {
      // Define a regular expression pattern for a valid email address
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      // Test the email against the pattern
      return emailPattern.test(email);
    },

    async btn_register() {
      try {
        this.error_message = "";
        this.success_message = true;
        this.btnDissable = true;
        if(this.name == null || this.name == "" || this.email == null || this.password == null){
          this.success_message = false;
          this.btnDissable = false;
          this.error_message = "Kindly Complete the form";
          return false;
        }
        else if(!this.validateEmail(this.email)){
          this.success_message = false;
          this.btnDissable = false;
          this.error_message = "Please enter correct email format.";
          return false;
        }
        else if(this.password.length < 8){
          this.success_message = false;
          this.btnDissable = false;
          this.error_message = "Password Must Be 8 Characters.";
          return false;
        }
        else if (this.password != this.retype_password) {
          this.success_message = false;
          this.btnDissable = false;
          this.error_message = "Password Not Confirmed.";
          return false;
        }
        else{
        const auth = getAuth();
        const firebase_response = await createUserWithEmailAndPassword(
          auth,
          this.email,
          this.password
        );
        const backend_response = await API.post("/firebase", {
          token: firebase_response?.user?.accessToken,
          name: this.name,timezone: this.getUserTimeZone(),
          email: this.email
        });
        this.btnDissable = false;
        console.log(firebase_response, backend_response);
        localStorage.setItem("token", backend_response.data.token);
        this.$store.state.user = backend_response.data.detail;
        localStorage.setItem("user", JSON.stringify(backend_response.data.detail));
        let has_promo = localStorage.getItem("has_promo");
        if (has_promo == "true") {
          this.$router.push(
            `/plans?promo_code=${localStorage.getItem("promo_code")}`
          );
        } else this.$router.push("/plans");
        }

      } catch (error) {
        this.success_message = false;
        this.btnDissable = false;
        console.log(error);
        this.error_message = error.code.split("/")[1].toUpperCase();
      }
    },
    async googleLogin() {
      try {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: "select_account",
        });
        const google_response = await signInWithPopup(auth, provider);
        console.log(google_response);
        const response_token = google_response.user.accessToken;

        const backend_response = await API.post("/firebase", {
          token: response_token,timezone: this.getUserTimeZone()
        });
        localStorage.setItem("token", backend_response.data.token);
        this.$store.state.user = backend_response.data.detail;
        // alert("Login successful.");
        // let has_promo = localStorage.getItem("has_promo");
        // if (has_promo == "true") {
        //   this.hasPromoRoute();
        // } else this.$router.push("/plans");
        this.$router.push("/plans");
      } catch (error) {
        console.log(error);
      }
    },
    
    showFeedback ({suggestions, warning}) {
        console.log('🙏', suggestions)
        console.log('⚠', warning)
      },
    showScore (score) {
      console.log('💯', score)
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if(/^[A-Z a-z]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text
    }     
  },
};
</script>

<style scoped src="../../public/assets/css/login-page.css"></style>
