import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VModal from 'vue-js-modal'
import { StripePlugin } from '@vue-stripe/vue-stripe';

const options = {
  pk: process.env.VUE_APP_STRIPE_KEY, //"pk_test_Le1AsEo6i0fWWvMQhEW5iKOZ",
  sk: process.env.VUE_APP_STRIPE_SECRET, //"sk_test_7YMTXGYgozwbhldIuY94KFHm",
  // pk: "pk_test_51Iw6BGDjK3I8iT1C8OpxJS1NMmfrawYMrEyCImc6vfp6c0ZLCDE2XyJntKNVmqlT1xcunnPftLIVlHyql3AZiz4I00ttM70Gvr",
};

Vue.use(StripePlugin, options);
Vue.use(require('vue-moment'));

// import firebase from "firebase";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

Vue.config.productionTip = false;
Vue.use(VueFormWizard)
Vue.use(VModal)
new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
